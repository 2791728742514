<template>
  <a-drawer
    title="其他信息"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 商户号 -->
      <a-form-item label="商户号" v-if="detail.mch_id">
        {{ detail.mch_id }}
      </a-form-item>
      <!-- 支付订单号 -->
      <a-form-item label="支付订单号">
        {{ detail.pay_trade_no }}
      </a-form-item>
      <!-- 退款单号 -->
      <a-form-item label="退款单号">
        {{ detail.refund_trade_no }}
      </a-form-item>
      <!-- 退款钻石 -->
      <a-form-item label="退款钻石" v-if="detail.refund_diamond">
        {{ detail.refund_diamond }} 个
      </a-form-item>
    </a-form>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import Loading from '@/components/Loading'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})

// 显示
function showDrawer (data) {
  // 获取详情
  detail.value = data
  // 显示
  visible.value = true
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.footer-view {
  display: flex;
  align-items: center;
}
.footer-hint {
  color: #5A5A5A;
}
</style>